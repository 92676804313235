export default function(fn: (() => unknown)): void {
  if (document.readyState != `loading`) {
    // When we import modules, pages are usually already loaded.
    // However, sometimes we write code with expectation that this function
    // (for example, we define a module-wide variables, onDocumentReady above everything,
    // which somehow modifies the variable (maybe through a function)).
    // setTimeout is there to delay the call of onDocumentReady callback,
    // so that all the module-wide variables are set
    setTimeout(fn);
  } else {
    document.addEventListener(`DOMContentLoaded`, fn);
  }
}
